import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';

import UtahFootballImage from '../../assets/images/publication-banners/utah-football.jpg';

const UtahFootball = (props) => (
  <Layout>
    <Helmet>
      <title>University of Utah Football - Mills Publishing Inc.</title>
      <meta
        name="description"
        content="Advertising and event information for the Utah Football season game day programs. Please contact Mills Publishing for availability."
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>University of Utah Football</h1>
          </header>
          <span className="image main">
            <img src={UtahFootballImage} alt="" />
          </span>
          <p className="box">
            Utah is coming off a shortened 2020 season when it recorded a
            winning record for the 14th time in the last 16 years. The Utes won
            back-to-back Pac-12 Conference South Division titles and appeared in
            the Pac-12 Championship in 2018 and 2019. Kyle Whittingham, who is
            back for his 17th season, is the longest-tenured head coach in the
            Pac-12 and has led the Utes to 134 victories, five double-digit win
            seasons, and 11 bowl wins. The Utes have six home games in 2021,
            welcoming Weber State, Washington State, Arizona State, UCLA,
            Oregon, and Colorado.
            <br />
            <br />
            Rice-Eccles Stadium has been sold out for 64 consecutive games when
            fans have been in attendance going back to the 2010 opener. This has
            fueled an 80 million dollar expansion that has enclosed the south
            end of the stadium to connect the east and west concourses - along
            with the new Ken Garff Performance Zone, which will add exciting
            premium seating options and increase the stadium’s capacity to more
            than 51,000 when the 2021 season begins.
            <br />
            <br />
            The 2021 Utah Football Yearbook will serve as a timeless keepsake
            for fans and feature official rosters, player headshots, and coaches
            bios. It will include an in-depth look at the tradition and history
            of the Utah football program, opponents’ quick facts and series
            breakdowns, as well as information on the University of Utah, the
            Pac-12 Conference, and Rice- Eccles Stadium.
          </p>
          <ul className="actions">
            <li>
              <a
                href="https://utahutes.com/sports/football"
                className="button special"
              >
                <i className="icon fa-globe"> </i>University of Utah Football
                Website
              </a>
            </li>
          </ul>
          <h2>
            University of Utah Football {new Date().getFullYear()} Season
            Information
          </h2>
          New season information coming soon.
          <div className="grid-wrapper">
            <div className="col-6 box">
              <h3>TBD</h3>
              <p>
                <strong>TBD</strong> <br />
                TBD
                <br />
                <br />
                <h5>Advertising Information</h5>
                <section className="box">
                  File Ready Deadline: TBD <br />
                  Circulation: TBD
                </section>
              </p>
            </div>
          </div>
          {/* <div className="grid-wrapper">
            <div className="col-12 box">
              <h3>2021 Football Yearbook</h3>
              <p>
                The Utah Football Yearbook will be distributed at each home game
                during this season and include a fresh (11” X 17”) pull-out
                poster with depth chart for each of the six games. An online
                copy of the Yearbook is available on smartphones. Access is
                available to fans and their friends during the games with a QR
                code posted in the Yearbook. Readership 80,000.
              </p>
              <h3>2021 Football Season Posters</h3>
              <p>
                The Utah Football Yearbook will be distributed at each home game
                during this season and include a fresh (11” X 17”) pull-out
                poster with depth chart for each of the six games.
              </p>
              <table>
                <thead>
                  <tr>
                    <th>Game Date</th>
                    <th>Opponent</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>September 2, 2021</td> <td>WASHINGTON STATE*</td>
                  </tr>
                  <tr>
                    <td>October 16, 2021</td> <td>ARIZONA STATE*</td>
                  </tr>
                  <tr>
                    <td>October 30, 2021</td> <td>UCLA*</td>
                  </tr>
                  <tr>
                    <td>November 20, 2021</td> <td>OREGON*</td>
                  </tr>
                  <tr>
                    <td>November 26, 2021</td> <td>COLORADO*</td>
                  </tr>
                </tbody>
              </table>
              <em>*Pac-12 Conference Game</em>
              <br />
              <br />
              <h5>Advertising Information</h5>
              <section className="box">
                File Ready Deadline: August 9, 2021 <br />
                Readership: 80,000
              </section>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  </Layout>
);

export default UtahFootball;
